<template>
    <div class="flex flex-col gap-1 text-[#FFF]">
        <b class="capitalize">{{ title }}</b>
        <p>{{ message }}</p>
        <a v-if="leanMoreUrl" @click.stop :href="leanMoreUrl" class="underline" target="_blank">Learn More</a>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            required: true
        },
        message: {
            type: String,
            required: true
        },
        leanMoreUrl: {
            type: String,
            required: false,
        }
    }
}
</script>